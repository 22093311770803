export enum Routes {
  INDEX = 'INDEX',
  PRODUCTS = 'PRODUCTS',
  PRODUCTS_TRANSPORT_BOXES = 'PRODUCTS_TRANSPORT_BOXES',
  PRODUCTS_FOOD_BOXES = 'PRODUCTS_FOOD_BOXES',
  PRODUCTS_LUXURY_LAMINATED_BOXES = 'PRODUCTS_LUXURY_LAMINATED_BOXES',
  PRODUCTS_PUNCHED_BOXES = 'PRODUCTS_PUNCHED_BOXES',
  PRODUCTS_WINE_BOXES = 'PRODUCTS_WINE_BOXES',
  PRODUCTS_OCTABIN_BOXES = 'PRODUCTS_OCTABIN_BOXES',
  PRODUCTS_CRATE_BOXES = 'PRODUCTS_CRATE_BOXES',
  PRODUCTS_PALLET_BOXES = 'PRODUCTS_PALLET_BOXES',
  PRODUCTS_PIZZA_BOXES = 'PRODUCTS_PIZZA_BOXES',
  PRODUCTS_GRAND_PLAYHOUSE = 'PRODUCTS_GRAND_PLAYHOUSE',
  BLOG = 'BLOG',
  BLOG_POST = 'BLOG_POST',
  COOKIE_POLICY = 'COOKIE_POLICY',
  PRIVACY_POLICY = 'PRIVACY_POLICY',
  ABOUT_US = 'ABOUT_US',
  CONTACTS = 'CONTACTS',
  FAQ = 'FAQ',
  THANK_YOU = 'THANK_YOU',
}
