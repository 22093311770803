import { graphql, useStaticQuery } from 'gatsby';

export const useSiteMetadata = () => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteTitle
            siteTitleAlt
            siteTitleTemplate
            siteDescription
            siteUrlWithTrailingSlash
            siteRobots
            siteMainLang
            social {
              facebook
              youtube
              gmapsLocation
            }
            contacts {
              email
              tel {
                titleTranslationKey
                nameBG
                nameEN
                phone
              }
            }
            company {
              businessHours {
                detailed {
                  dayTranslationKey
                  hours
                }
                short
              }
            }
          }
        }
      }
    `
  );
  return site.siteMetadata;
};
