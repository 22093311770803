import * as React from 'react';
import * as styles from './header-nav.module.scss';
import { FormattedMessage, Link } from 'gatsby-plugin-react-intl';
import { Link as ILink } from '../../interfaces/Link';

interface IProps {
  links: ILink[];
  className?: string;
}
export const HeaderNav = ({ links = [], className = '' }: IProps) => (
  <nav className={`${styles.nav} ${className}`}>
    <ul
      className={`${styles.list} d-md-flex align-items-md-center list-unstyled`}
    >
      {links.map(link => (
        <li key={link.translation}>
          <Link to={link.path} activeClassName="active">
            <FormattedMessage id={link.translation} />
          </Link>
        </li>
      ))}
    </ul>
  </nav>
);
