import * as React from 'react';
import * as styles from './header.module.scss';
import { StaticImage } from 'gatsby-plugin-image';
import { useIntl, Link } from 'gatsby-plugin-react-intl';
import { HeaderNav } from '../header-nav/header-nav';
import { LanguageNav } from '../language-nav/language-nav';
import { IconButton } from '@material-ui/core';
import { Menu } from '@material-ui/icons';
import { Sidenav } from '../sidenav/sidenav';
import { Link as ILink } from '../../interfaces/Link';
import { ROUTES } from '../../const/routes';
import { Routes } from '../../enum/Routes';

export const Header = () => {
  const intl = useIntl();

  const headerLinks: ILink[] = [
    {
      translation: 'header.nav.home',
      path: ROUTES[Routes.INDEX](),
    },
    {
      translation: 'header.nav.products',
      path: ROUTES[Routes.PRODUCTS](),
    },
    {
      translation: 'header.nav.about_us',
      path: ROUTES[Routes.ABOUT_US](),
    },
    {
      translation: 'header.nav.contacts',
      path: ROUTES[Routes.CONTACTS](),
    },
  ];

  const [drawerState, setDrawerState] = React.useState(false);
  const toggleDrawer = () => {
    setDrawerState(!drawerState);
  };

  return (
    <header className={styles.header}>
      <div className="container d-flex align-items-center justify-content-between">
        <Link to={ROUTES[Routes.INDEX]()}>
          <StaticImage
            src="../../images/Logo-min.png"
            className="d-none d-md-block"
            loading="eager"
            placeholder="tracedSVG"
            width={112}
            quality={95}
            formats={['auto', 'webp', 'avif']}
            alt={intl.formatMessage({ id: 'header.logo_alt' })}
          />
          <StaticImage
            src="../../images/Logo-min.png"
            className="d-md-none"
            loading="eager"
            placeholder="tracedSVG"
            width={50}
            quality={95}
            formats={['auto', 'webp', 'avif']}
            alt={intl.formatMessage({ id: 'header.logo_alt' })}
          />
        </Link>
        <HeaderNav className="d-none d-md-block" links={headerLinks} />
        <LanguageNav className="d-none d-md-block" />
        <IconButton
          aria-label={intl.formatMessage({ id: 'header.menu_btn' })}
          className={`${styles.menuIcon} d-md-none`}
          onClick={toggleDrawer}
        >
          <Menu />
        </IconButton>
        <Sidenav drawerState={drawerState} onClose={toggleDrawer}>
          <div className={`${styles.languageNav} d-flex justify-content-end`}>
            <LanguageNav />
          </div>
          <HeaderNav links={headerLinks} />
        </Sidenav>
      </div>
    </header>
  );
};
