import React from 'react';
import { Layout } from '../../components/layout/layout';
import { Header } from '../../components/header/header';
import { Footer } from '../../components/footer/footer';
import { CookieModal } from '../../components/cookie-modal/cookie-modal';

interface IProps {
  children: React.ReactElement | React.ReactElement[];
  className?: string;
}

export const MainTemplate = ({ children, className }: IProps) => {
  return (
    <Layout>
      <Header />
      <main className={className}>{children}</main>
      <CookieModal />
      <Footer />
    </Layout>
  );
};
