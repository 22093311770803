import * as React from 'react';
import * as styles from './footer-nav-box.module.scss';
import { FormattedMessage, Link } from 'gatsby-plugin-react-intl';
import { IFooterNavBox } from '../../interfaces/FooterNavBox';
import { Link as ILink } from '../../interfaces/Link';
import { LinkType } from '../../enum/LinkType';

interface IProps {
  className: string;
  navBoxData: IFooterNavBox;
}
export const FooterNavBox = ({ className = '', navBoxData }: IProps) => {
  const renderLink = ({ type, path, translation }: ILink) => {
    switch (type) {
      case LinkType.EXTERNAL:
        return (
          <a href={path} rel="noopener" target="_blank">
            <FormattedMessage id={translation} />
          </a>
        );
      case LinkType.EMAIL:
        return (
          <a href={`mailto:${path}`}>
            <FormattedMessage id={translation} />
          </a>
        );
      case LinkType.TEL:
        return (
          <a href={`tel:${path}`}>
            <FormattedMessage id={translation} /> {path}
          </a>
        );
      default:
        return (
          <Link to={path}>
            <FormattedMessage id={translation} />
          </Link>
        );
    }
  };

  return (
    <div className={`${styles.navBox} ${className}`}>
      <strong>
        <FormattedMessage id={navBoxData.headline} />
      </strong>
      <ul className="list-unstyled">
        {navBoxData.links.map(link => (
          <li key={link.translation}>{renderLink(link)}</li>
        ))}
      </ul>
    </div>
  );
};
