import React from 'react';
import '../../styles/_layout.scss';
import { createTheme, ThemeProvider } from '@material-ui/core';
import { useIntl, IntlProvider } from 'gatsby-plugin-react-intl';

const theme = createTheme({
  palette: {
    primary: {
      main: '#424242',
      light: '#6d6d6d',
      dark: '#1b1b1b',
      contrastText: '#FFF',
    },
    secondary: {
      main: '#fbc02d',
      light: '#fff263',
      dark: '#c29000',
      contrastText: '#1b1b1b',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1200,
    },
  },
});

interface ILayoutProps {
  children: React.ReactElement | React.ReactElement[];
}

export const Layout = ({ children }: ILayoutProps) => {
  const intl = useIntl();

  return (
    <ThemeProvider theme={theme}>
      <IntlProvider
        locale={intl.locale}
        defaultLocale={intl.defaultLocale}
        messages={intl.messages}
      >
        {children}
      </IntlProvider>
    </ThemeProvider>
  );
};
