import { Routes } from '../enum/Routes';

export const ROUTES = {
  [Routes.INDEX]: () => '/',
  [Routes.PRODUCTS]: () => '/products/',
  [Routes.PRODUCTS_TRANSPORT_BOXES]: () => '/products/transport-boxes/',
  [Routes.PRODUCTS_FOOD_BOXES]: () => '/products/food-boxes/',
  [Routes.PRODUCTS_LUXURY_LAMINATED_BOXES]: () =>
    '/products/luxury-laminated-boxes/',
  [Routes.PRODUCTS_PUNCHED_BOXES]: () => '/products/punched-boxes/',
  [Routes.PRODUCTS_WINE_BOXES]: () => '/products/wine-boxes/',
  [Routes.PRODUCTS_OCTABIN_BOXES]: () => '/products/octabin-boxes/',
  [Routes.PRODUCTS_PALLET_BOXES]: () => '/products/pallet-boxes/',
  [Routes.PRODUCTS_CRATE_BOXES]: () => '/products/crate-boxes/',
  [Routes.PRODUCTS_PIZZA_BOXES]: () => '/products/pizza-boxes/',
  [Routes.PRODUCTS_GRAND_PLAYHOUSE]: () => '/products/grand-playhouse/',
  [Routes.BLOG]: () => '/blog/',
  [Routes.BLOG_POST]: (slug: string) => `/blog/${slug}/`,
  [Routes.PRIVACY_POLICY]: () => '/privacy-policy/',
  [Routes.COOKIE_POLICY]: () => '/cookie-policy/',
  [Routes.ABOUT_US]: () => '/about-us/',
  [Routes.CONTACTS]: () => '/contacts/',
  [Routes.FAQ]: () => '/frequently-asked-questions/',
  [Routes.THANK_YOU]: () => '/thank-you/',
};
