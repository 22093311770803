import * as React from 'react';
import * as styles from './footer.module.scss';
import { useSiteMetadata } from '../../hooks/useSiteMetadata';
import { FooterNavBox } from '../footer-nav-box/footer-nav-box';
import { IFooterNavBox } from '../../interfaces/FooterNavBox';
import { ROUTES } from '../../const/routes';
import { Routes } from '../../enum/Routes';
import { LinkType } from '../../enum/LinkType';

export const Footer = () => {
  const { siteTitle, social, contacts } = useSiteMetadata();

  const phoneNumberToDisplay = contacts.tel[0]?.phone;

  const footerNavBoxes: IFooterNavBox[] = [
    {
      headline: 'footer.categories.products.headline',
      links: [
        {
          translation: 'footer.categories.products.nav.boxes',
          path: ROUTES[Routes.PRODUCTS_TRANSPORT_BOXES](),
        },
        {
          translation: 'footer.categories.products.nav.pizza_boxes',
          path: ROUTES[Routes.PRODUCTS_PIZZA_BOXES](),
        },
        {
          translation: 'footer.categories.products.nav.food_boxes',
          path: ROUTES[Routes.PRODUCTS_FOOD_BOXES](),
        },
        {
          translation: 'footer.categories.products.nav.luxury_boxes',
          path: ROUTES[Routes.PRODUCTS_LUXURY_LAMINATED_BOXES](),
        },
      ],
    },
    {
      headline: 'footer.categories.policies.headline',
      links: [
        {
          translation: 'footer.categories.policies.nav.privacy_policy',
          path: ROUTES[Routes.PRIVACY_POLICY](),
        },
        {
          translation: 'footer.categories.policies.nav.cookie_policy',
          path: ROUTES[Routes.COOKIE_POLICY](),
        },
      ],
    },
    {
      headline: 'footer.categories.pages.headline',
      links: [
        {
          translation: 'footer.categories.pages.nav.about_us',
          path: ROUTES[Routes.ABOUT_US](),
        },
        {
          translation: 'footer.categories.pages.nav.products',
          path: ROUTES[Routes.PRODUCTS](),
        },
        {
          translation: 'footer.categories.pages.nav.faq',
          path: ROUTES[Routes.FAQ](),
        },
        {
          translation: 'footer.categories.pages.nav.blog',
          path: ROUTES[Routes.BLOG](),
        },
      ],
    },
    {
      headline: 'footer.categories.contacts.headline',
      links: [
        {
          translation: 'general.company.address',
          path: social.gmapsLocation,
          type: LinkType.EXTERNAL,
        },
        {
          translation: 'footer.categories.contacts.nav.phone',
          path: phoneNumberToDisplay,
          type: LinkType.TEL,
        },
        {
          translation: 'general.company.email',
          path: contacts.email,
          type: LinkType.EMAIL,
        },
      ],
    },
  ];

  return (
    <footer className={styles.footer}>
      <div className="container">
        <nav className="row">
          {footerNavBoxes.map((navBox, index) => (
            <FooterNavBox
              key={index}
              className="col-12 col-md-3"
              navBoxData={navBox}
            />
          ))}
        </nav>
        <div className={styles.copyright}>
          © {new Date().getFullYear()}, {siteTitle}
        </div>
      </div>
    </footer>
  );
};
