import * as React from 'react';
import { useLocation } from '@reach/router';
import { useEffect, useState } from 'react';
import * as styles from './cookie-modal.module.scss';
import { FormattedMessage, Link } from 'gatsby-plugin-react-intl';
import { initializeAndTrack } from '@mobile-reality/gatsby-plugin-gdpr-cookies';
import { ROUTES } from '../../const/routes';
import { Routes } from '../../enum/Routes';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
  Theme
} from '@material-ui/core';
import { useSiteMetadata } from '../../hooks/useSiteMetadata';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    position: 'relative',
    background: theme.palette.primary.dark,
    color: theme.palette.primary.contrastText,
    borderRadius: '6px 6px 0 0',
    [theme.breakpoints.up('md')]: {
      borderRadius: '10px 10px 10px 10px'
    }
  }
}));

const cookiesPreferencesSetCookieName = 'cookies-preferences-set';
const pagesCookieModalDisabled = [ROUTES[Routes.COOKIE_POLICY]()];

export const CookieModal = () => {
  const location = useLocation();
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const { siteTitle } = useSiteMetadata();

  useEffect(() => {
    const isOnPageWithCookieConsentModalDisabled = pagesCookieModalDisabled.includes(location.pathname);
    if (
      !isOnPageWithCookieConsentModalDisabled
    ) {
      setIsOpen(!document.cookie.includes(cookiesPreferencesSetCookieName));
    }
  }, []);

  const setCookies = (value: boolean) => {
    // GA GCM V2
    document.cookie = `gatsby-gdpr-google-ad-storage=${value}`;
    document.cookie = `gatsby-gdpr-google-analytics-storage=${value}`;
    document.cookie = `gatsby-gdpr-google-functionality-storage=${value}`;
    document.cookie = `gatsby-gdpr-google-personalization-storage=${value}`;
    document.cookie = `gatsby-gdpr-google-ad-user-data=${value}`;
    document.cookie = `gatsby-gdpr-google-ad-personalization=${value}`;

    // GA
    document.cookie = `gatsby-gdpr-google-analytics=${value}`;
    // FB Pixel
    document.cookie = `gatsby-gdpr-facebook-pixel=${value}`;

    // Preferences set - both for accepted and rejected cookies.
    document.cookie = `${cookiesPreferencesSetCookieName}=true`;
  };

  const handleAcceptCookies = () => {
    setCookies(true);

    initializeAndTrack(location);
    setIsOpen(false);
  };

  const handleRejectCookies = () => {
    setCookies(false);

    initializeAndTrack(location);
    setIsOpen(false);
  };

  const handleCloseModal = () => {
    if (document.cookie.includes(cookiesPreferencesSetCookieName)) {
      setIsOpen(false);
    }
  };

  return (
    <Dialog
      classes={classes}
      fullWidth={true}
      maxWidth="sm"
      open={isOpen}
      onClose={handleCloseModal}
      aria-labelledby="cookie-consent-modal-title"
    >
      <DialogTitle className={styles.title} id="cookie-consent-modal-title">
        {siteTitle} иска вашето съгласие, да използва бисквитки.
      </DialogTitle>
      <DialogContent>
        <DialogContentText className={styles.text}>
          <FormattedMessage id="general.cookie_modal.part_one" />{' '}
          <Link to={ROUTES[Routes.COOKIE_POLICY]()} className={styles.link}>
            <FormattedMessage id="general.cookie_modal.link" />
          </Link>{' '}
          <FormattedMessage id="general.cookie_modal.part_two" />
        </DialogContentText>
      </DialogContent>
      <DialogActions className={styles.buttons}>
        <button className={styles.acceptButton} onClick={handleAcceptCookies}>
          <FormattedMessage id="general.cookie_modal.btn_accept" />
        </button>
        <button className={styles.rejectButton} onClick={handleRejectCookies}>
          <FormattedMessage id="general.cookie_modal.btn_reject" />
        </button>
      </DialogActions>
    </Dialog>
  );
};
