import * as React from 'react';
import { Drawer, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  paper: {
    background: theme.palette.primary.dark,
    padding: '20px 0',
    textDecoration: 'none',
    width: '250px',
  },
}));

interface IProps {
  children: React.ReactElement | React.ReactElement[];
  drawerState: boolean;
  onClose: any;
}
export const Sidenav = ({ children, drawerState, onClose }: IProps) => {
  const classes = useStyles();

  return (
    <Drawer
      classes={classes}
      className="d-md-none"
      anchor="right"
      open={drawerState}
      onClose={onClose}
      disableRestoreFocus={true}
    >
      {children}
    </Drawer>
  );
};
