import * as React from 'react';
import { Helmet } from 'react-helmet';
import { useSiteMetadata } from '../../hooks/useSiteMetadata';
import { IntlContextConsumer, useIntl } from 'gatsby-plugin-react-intl';
import { useObtainDomainFromUrl } from '../../hooks/useObtainDomainFromUrl';
import logoImage from '../../images/Logo-min.png';

interface ISEOProps {
  title: string;
  titleTemplate?: string;
  description?: string;
  robots?: string;
  meta?: {}[];
  schemaMarkup?: any;
  location: Location;
}
export const SEO = ({
  title,
  titleTemplate,
  description,
  robots,
  schemaMarkup,
  location,
}: ISEOProps) => {
  const intl = useIntl();
  const {
    siteTitle,
    siteTitleTemplate,
    siteDescription,
    siteRobots,
    siteUrlWithTrailingSlash,
  } = useSiteMetadata();
  const siteDomain = useObtainDomainFromUrl(siteUrlWithTrailingSlash);

  const siteUrlWithNoTrailingSlash = siteUrlWithTrailingSlash.replace(
    /\/$/,
    ''
  );
  const currentPageUrl = `${siteUrlWithNoTrailingSlash}${location?.pathname}`;

  const metaTitle = title || siteTitle;
  const metaTitleTemplate = titleTemplate || siteTitleTemplate;
  const metaDescription = description || siteDescription;

  const metaTagNameArray = [
    {
      name: 'robots',
      content: robots ? robots : siteRobots,
    },
  ];

  const metaTagOpenGraphArray = [
    {
      property: `og:url`,
      content: currentPageUrl,
    },
    {
      property: `og:title`,
      content: metaTitle,
    },
    {
      property: `og:description`,
      content: metaDescription,
    },
    {
      property: `og:type`,
      content: `website`,
    },
    {
      property: `og:image`,
      content: logoImage,
    },
  ];

  const twitterMetaTagsArray = [
    {
      name: `twitter:card`,
      content: `summary_large_image`,
    },
    {
      property: `twitter:domain`,
      content: siteDomain,
    },
    {
      property: `twitter:url`,
      content: currentPageUrl,
    },
    {
      name: `twitter:title`,
      content: metaTitle,
    },
    {
      name: `twitter:description`,
      content: metaDescription,
    },
    {
      property: `twitter:image`,
      content: logoImage,
    },
  ];

  const pathnameWithoutLocale = location?.pathname.replace(/^\/[\w]{2}\//, '/');

  const generateCanonicalUrl = (currentLanguage: string): string => {
    return `${siteUrlWithNoTrailingSlash}${
      currentLanguage === intl.defaultLocale
        ? pathnameWithoutLocale
        : location?.pathname
    }`;
  };

  const generateAlternateUrl = (language: string): string => {
    return `${siteUrlWithTrailingSlash}${
      language !== intl.defaultLocale ? `${language}/` : ''
    }${pathnameWithoutLocale.replace(/\//, '')}`;
  };

  return (
    <IntlContextConsumer>
      {({
        languages,
        language: currentLanguage,
      }: {
        languages: string[];
        language: string;
      }) => {
        const canonicalLink = (
          <link rel="canonical" href={generateCanonicalUrl(currentLanguage)} />
        );
        const alternateLinks = languages.map(
          language =>
            language !== currentLanguage && (
              <link
                key={language}
                rel="alternate"
                href={generateAlternateUrl(language)}
                hrefLang={language}
              />
            )
        );

        return (
          <Helmet
            htmlAttributes={{
              lang: intl.locale ? intl.locale : intl.defaultLocale,
            }}
            title={metaTitle}
            titleTemplate={metaTitleTemplate}
            meta={[
              {
                name: 'description',
                content: metaDescription,
              },
              ...metaTagNameArray,
              ...metaTagOpenGraphArray,
              ...twitterMetaTagsArray,
            ]}
          >
            {schemaMarkup && (
              <script type="application/ld+json">
                {JSON.stringify(schemaMarkup)}
              </script>
            )}
            {alternateLinks}
            {canonicalLink}
          </Helmet>
        );
      }}
    </IntlContextConsumer>
  );
};
