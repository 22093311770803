import * as React from 'react';
import * as styles from './language-nav.module.scss';
import { IntlContextConsumer, changeLocale } from 'gatsby-plugin-react-intl';

const languageNames = {
  bg: 'BG',
  en: 'EN',
  ru: 'RU',
};

interface IProps {
  className?: string;
}

export const LanguageNav = ({ className = '' }: IProps) => {
  return (
    <nav className={`${styles.nav} ${className}`}>
      <ul className={`${styles.list} d-flex align-items-center list-unstyled`}>
        <IntlContextConsumer>
          {({
            languages,
            language: currentLocale,
          }: {
            languages: string[];
            language: string;
          }) =>
            languages.map(language => (
              <li key={language}>
                <a
                  tabIndex={0}
                  role="button"
                  onClick={() => changeLocale(language)}
                  className={`${styles.link} ${
                    currentLocale === language ? styles.active : ''
                  }`}
                >
                  {languageNames[language]}
                </a>
              </li>
            ))
          }
        </IntlContextConsumer>
      </ul>
    </nav>
  );
};
